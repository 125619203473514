body {
	background: #eaf8ff;
}
h2 {
	padding-top: 20px;
	padding-left: 15px;
	padding-bottom: 0px;
	font-size: 26px;
	font-family: 'Arial', 'Verdana', 'Trebuchet';
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.448px;
        color: #008dd8;
	line-height: 0;
}
img.valvontakuva {
	width: 250px;
	height: 280px; 	
}
table.room {
	background: #ffffff;
	margin-right: 7px;
	margin-left: 7px;
	margin-top: 7px;
	margin-bottom: 7px;
}
table.huone_ylaosa {
	width: 100%;
	height: 35px;
}

td.huone_nimi {
	padding-top: 5px;
  	padding-left: 5px;
	verticalAlign: 'top';
        font-size: 18px;
        font-family: 'Arial', 'Verdana', 'Trebuchet';
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.448px;
        color: #008dd8;
}
td.huone_tila {
  	text-align: right;
	padding-top: 5px;
	padding-right: 5px;
	vertical-align: top;
        font-size: 18px;
        font-family: 'Arial', 'Verdana', 'Trebuchet';
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.448px;
        color: #008dd8;
}
td.info {
	font-size: 12px;				
	font-family: 'Arial', 'Verdana', 'Trebuchet';
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.448px;
        color: #008dd8;
	padding-top: 5px;				
	padding-left: 7px;				
	height: 20px;
}
td.info_alarm {
	font-size: 12px;
	font-family: 'Arial', 'Verdana', 'Trebuchet';
	font-style: bold;
	font-weight: 950;
	letter-spacing: 0.448px;
	color: #ff0000;
	padding-top: 5px;
	padding-left: 7px;
	height: 20px;
	}
td.events {
	text-align: right;
        font-size: 12px;
        font-family: 'Arial', 'Verdana', 'Trebuchet';
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.448px;
        color: #008dd8;
        padding-top: 5px;
        padding-left: 7px;
        height: 20px;

}

div.timer {
	padding-left: 5px;
	height: 40px;
}
  
.MenuItem {
	color: #008dd8;
  }
